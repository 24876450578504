import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const UnexpectedErrorPage = () => {
    return (
        <Layout lang={'en'}>
            <SEO title="Unexpected error" />
            <div
                style={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <h1>Oops!</h1>
                <p>
          Looks like something went wrong. We are working on it. / Parece que
          algo salió mal. Estamos trabajando para solucionarlo.
                </p>
                <Link to={'/'}>Go home</Link>
                <Link to={'/es/'}>Ir al inicio</Link>
            </div>
        </Layout>
    )
}

export default UnexpectedErrorPage
